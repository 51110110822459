import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../utils/api";

export default function EventBlogById() {
 const { id } = useParams();
 const [document, setDocument] = useState(null);
 const [loading, setLoading] = useState(true);
 const [error, setError] = useState(null);

 useEffect(() => {
  const fetchDocument = async () => {
   try {
    const response = await api.get(`/document/doc/${id}`);
    setDocument(response.data);
   } catch (err) {
    setError(err.response?.data?.message || "Error fetching document");
   } finally {
    setLoading(false);
   }
  };

  fetchDocument();
 }, [id]);

 if (loading) return <p>Loading...</p>;
 if (error) return <p>Error: {error}</p>;

 return (
  <div className="container px-4 pt-32 mx-auto">
   <div className="flex flex-col items-center gap-4 max-w-4xl mx-auto">
    <p className="bg-[#FFF1C5] p-2 rounded-full text-sm md:text-base">{document?.popularTopics || "CS2, DotA 2 & eFootball"}</p>
    <h1 className="text-2xl md:text-4xl text-center font-bold">{document?.title || "Title"}</h1>
    <p className="text-[#B3B3B3] text-center text-sm md:text-base">
     {document.eventDate ? new Date(document.eventDate).toLocaleDateString() : new Date(document.addedAt).toLocaleDateString()}
    </p>
    <img
     src={document?.path ? `https://api.fesk.gg/docs/${document.path}` : "/assets/images/imageEvent.svg"}
     alt="main"
     className="w-full min-w-[100%] h-[200px] md:h-auto object-cover"
     crossOrigin="anonymous"
    />

    <div className="bg-[#D9D9D9] my-4 w-full h-px"></div>
    <p className="mt-4 mb-12" dangerouslySetInnerHTML={{ __html: document?.description }} />
   </div>
  </div>
 );
}

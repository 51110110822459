import React from "react";
import Header from "../containers/HomePage/Header";
import Sponsors from "../containers/HomePage/Sponsors";
import AboutUsHome from "../containers/HomePage/AboutUsHome";
import EventsHome from "../containers/HomePage/EventsHome";
import ClubsHome from "../containers/HomePage/ClubsHome";
import LatestEventsHome from "../containers/HomePage/LatestEventsHome";

export default function HomePage() {
 return (
  <div>
   <Header />
   <div className="container">
    <Sponsors />
    {/* <AboutUsHome /> */}
    <EventsHome />
    {/* <ClubsHome /> */}
    {/* <LatestEventsHome /> */}
   </div>
  </div>
 );
}

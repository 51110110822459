import { useEffect, useState } from "react";
import background from "../../mediaAssets/bg5.jpg";
import NewsCardHome from "../../components/Cards/NewsCardHome";
import api from "../../utils/api";
import EventCard from "../../components/Cards/EventCard";

export default function Events() {
 const [document, setDocument] = useState(null);
 const [loading, setLoading] = useState(true);
 const [error, setError] = useState(null);

 useEffect(() => {
  const fetchDocument = async () => {
   try {
    const response = await api.get(`/document`);
    setDocument(response.data);
   } catch (err) {
    setError(err.response?.data?.message || "Error fetching document");
   } finally {
    setLoading(false);
   }
  };

  fetchDocument();
 }, []);

 if (loading) return <p>Loading...</p>;
 if (error) return <p>Error: {error}</p>;
 return (
  <div>
   {" "}
   <div
    className="relative overflow-hidden  bg-cover bg-no-repeat p-12 text-center"
    style={{ backgroundImage: `url(${background})`, height: "500px" }}
   >
    <div
     className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed"
     style={{ backgroundColor: "rgba(0.4, 0, 0, 0.6)" }}
    >
     <div className="flex flex-col h-full items-center justify-center">
      <h1 className="bg-themecolor p-2 text-white rounded-lg font-bold w-[150px] text-[20px]" data-aos="zoom-in-down">
       FESK Events
      </h1>
      <div className="text-white">
       <h2 className="text-[40px] font-semibold py-4" data-aos="zoom-out-up">
        Evente te ardhshme
       </h2>
      </div>
     </div>
    </div>
   </div>
   <div className="container !mt-[-80px] !mb-[80px]">
    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6" data-aos="zoom-in-down">
     {document
      .filter((event) => event.category === "Event")
      .map((event) => (
       <EventCard
        key={event._id}
        id={event._id}
        title={event.title}
        description={event.desc || event.description}
        image={event.path ? `https://api.fesk.gg/docs/${event.path}` : null}
        category={event.category}
        addedAt={event.addedAt}
        addedBy={`${event.addedBy.firstName} ${event.addedBy.lastName}`}
       />
      ))}
    </div>
   </div>
  </div>
 );
}

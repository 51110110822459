import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../utils/api";

const Apply = () => {
 const [isModalOpen, setIsModalOpen] = useState(false);
 const [selectedTeam, setSelectedTeam] = useState(null);
 const [teams, setTeams] = useState([]);
 const [loading, setLoading] = useState(true);
 const [error, setError] = useState("");

 useEffect(() => {
  const fetchTeams = async () => {
   try {
    const response = await api.get("/branch");
    const sanitizedTeams = response.data.map((team) => ({
     ...team,
     players: team.players.map((player) => {
      const { password, ...playerData } = player;
      return playerData;
     }),
     coach: team.coach ? (({ password, ...coachData }) => coachData)(team.coach) : null,
    }));
    setTeams(sanitizedTeams);
   } catch (err) {
    setError("Failed to fetch teams");
   } finally {
    setLoading(false);
   }
  };

  fetchTeams();
 }, []);

 const openModal = (team) => {
  setSelectedTeam(team);
  setIsModalOpen(true);
 };

 const closeModal = () => {
  setIsModalOpen(false);
 };

 if (loading) {
  return <div>Loading...</div>;
 }

 if (error) {
  return <div>{error}</div>;
 }

 const getInitials = (firstName, lastName) => {
  const firstInitial = firstName ? firstName.charAt(0).toUpperCase() : "";
  const lastInitial = lastName ? lastName.charAt(0).toUpperCase() : "";
  return `${firstInitial}${lastInitial}`;
 };

 return (
  <div className="container mx-auto my-8 px-4">
   <div className="pt-[150px] text-center">
    <h1 className="font-semibold text-[40px]">Teams</h1>
    <p className="pt-4 mb-12 text-[20px]">Bashkohuni me ne per te shendrruar pasionin tuaj per lojrat elektronike ne sukses!</p>
   </div>
   <div className="overflow-x-auto">
    <table className="w-full text-left border-collapse mb-12">
     <thead>
      <tr>
       <th className="py-4 px-6 bg-gray-100 font-semibold text-sm">Team</th>
       <th className="py-4 px-6 bg-gray-100 font-semibold text-sm">Players</th>
       <th className="py-4 px-6 bg-gray-100 font-semibold text-sm">Coach</th>
       <th className="py-4 px-6 bg-gray-100 font-semibold text-sm">Game</th>
       <th className="py-4 px-6 bg-gray-100 font-semibold text-sm">Action</th>
      </tr>
     </thead>
     <tbody>
      {teams.map((team, index) => (
       <tr key={index} className="border-t">
        <Link to={`/team/${team._id}`} className="text-themecolor">
         <td className="py-4 px-6">{team.name}</td>
        </Link>
        <td className="py-4 px-6">
         <div className="flex">
          {team.players.map((player, idx) => (
           <div key={idx} className="w-8 h-8 flex items-center justify-center rounded-full border-2 border-white">
            {player.image ? (
             <img src={player.image} alt="Player" className="w-full h-full object-cover rounded-full" />
            ) : (
             <div className="border p-2 rounded-full">{getInitials(player.firstName, player.lastName)}</div>
            )}
           </div>
          ))}
         </div>
        </td>

        <td className="py-4 px-6">
         <div className="w-8 h-8 flex items-center justify-center rounded-full border-2 border-white">
          {team.coach?.image ? (
           <img src={team.coach.image} alt="Coach" className="w-full h-full object-cover rounded-full" />
          ) : (
           <div className="border p-2 rounded-full">{getInitials(team.coach?.firstName, team.coach?.lastName)}</div>
          )}
         </div>
        </td>

        <td className="py-4 px-6">{team.game}</td>
        <td className="py-4 px-6">
         <button className="bg-blue-600 text-white py-2 px-4 rounded" onClick={() => openModal(team)}>
          Apply
         </button>
        </td>
       </tr>
      ))}
     </tbody>
    </table>
   </div>

   {/* Modal for applying to a team */}
   {isModalOpen && selectedTeam && (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
     <div className="bg-white p-8 rounded-lg">
      <h2 className="text-xl mb-4">Apply to {selectedTeam.name}</h2>
      <form>
       <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">First Name</label>
        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" type="text" placeholder="Enter your first name" />
       </div>
       <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Last Name</label>
        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" type="text" placeholder="Enter your last name" />
       </div>
       <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Email</label>
        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" type="email" placeholder="Enter your email" />
       </div>
       <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Why do you want to apply to this team?</label>
        <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" placeholder="Write your message" />
       </div>
       <div className="flex justify-end">
        <button type="button" className="bg-gray-500 text-white py-2 px-4 rounded mr-2" onClick={closeModal}>
         Cancel
        </button>
        <button type="submit" className="bg-blue-600 text-white py-2 px-4 rounded">
         Submit
        </button>
       </div>
      </form>
     </div>
    </div>
   )}
  </div>
 );
};

export default Apply;

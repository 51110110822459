import React from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

export default function Footer() {
 const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

 return (
  <div className="bg-themecolor text-white">
   <div className="container mx-auto px-4">
    <div className="flex flex-col md:flex-row justify-between py-12">
     <div className="w-full md:w-1/2 mb-8 md:mb-0">
      <img alt="logo" src="/assets/images/fesklogo.svg" className="w-[200px] mx-auto md:mx-0" />
     </div>
     <div className="w-full md:w-1/2 flex flex-col md:flex-row justify-between text-[16px]">
      <ul className="flex flex-col gap-2 mb-8 md:mb-0">
       <li className="font-bold mb-4">INFO</li>
       <Link to="/">
        <li>Home</li>
       </Link>
       <Link to="/events">
        <li>Events</li>
       </Link>
       {/* <Link to="/apply">
        <li>Team</li>
       </Link> */}
       {/* <Link to="/login">
        <li>Apliko ne nje ekip</li>
       </Link> */}
      </ul>
      <ul className="flex flex-col gap-2 mb-8 md:mb-0">
       <li className="font-bold mb-4">ABOUT</li>
       <Link to="/about-us">
        <li>About us</li>
       </Link>
       <Link to="/contact-us">
        <li>Contact us</li>
       </Link>
      </ul>
      <ul className="flex flex-col gap-2">
       <li className="font-bold mb-4">CONTACT US</li>
       <li>1901 PRISTHINE 1000</li>
       {/* <li>045707907</li> */}
       <li>info@fesk.gg</li>
      </ul>
     </div>
    </div>
    <div className="flex flex-col md:flex-row justify-between pb-4 text-center md:text-left">
     <p>© 2024 — Copyright</p>
     <div className="flex justify-center md:justify-start gap-4 mt-4 md:mt-0">
      <img alt="social media" src="/assets/images/socialmedia/instagramFesk.svg" className="cursor-pointer" />
      <img alt="social media" src="/assets/images/socialmedia/facebookFesk.svg" className="cursor-pointer" />
     </div>
    </div>
   </div>
   {/* <div className="bg-gradient-to-r from-cyan-900 to-themecolor">
    <Link to="/apply">
     <div className="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center py-4">
      <div className="flex items-center gap-2">
       {!isMobile && <img alt="social media" src="/assets/images/line.svg" />}
       <h1 className="md:text-[30px] text-[15px] text-center md:text-left">APLIKO NE EKIPIN E DESHIRUAR!</h1>
      </div>
      {!isMobile && <img alt="social media" src="/assets/images/arrowFesk.svg" className="cursor-pointer mt-4 md:mt-0" />}
     </div>
    </Link>
   </div> */}
  </div>
 );
}

import React from "react";

export default function Sponsors() {
 return (
  <div className=" text-center md:text-left my-12 ">
   <h1 className="text-[40px] font-bold">Sponzorët</h1>
   <div className="flex flex-col gap-12 md:flex-row items-center justify-between my-8">
    <img src="/assets/images/partners/ministria.png" alt="partners" />
    <img src="/assets/images/partners/exfis.png" alt="partners" />
    <img src="/assets/images/partners/telkos.png" alt="partners" />
    <img src="/assets/images/partners/rugove.png" alt="partners" />
    <img src="/assets/images/partners/kfc.svg" alt="partners" />
    <img src="/assets/images/partners/interex.svg" alt="partners" />
   </div>
  </div>
 );
}

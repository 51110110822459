import React from "react";
import { Link } from "react-router-dom";

export default function EventCard({ id, date, title, image }) {
 return (
  <Link to={`/events/${id}`}>
   <div className="relative md:mr-4 cursor-pointer group transition-transform duration-300 transform hover:scale-[0.98] hover:shadow-4xl">
    <div className="relative rounded-lg overflow-hidden">
     <img
      src={image || "/assets/images/imageEvent.svg"}
      alt="event"
      className="w-full object-cover h-[400px]"
      crossOrigin="anonymous"
      style={{ filter: "brightness(0.7)" }}
     />
     <div className="absolute inset-0 bg-black/30"></div>
    </div>
    <div className="absolute bottom-5 left-5 text-white">
     <p className="text-[18px]">{date}</p>
     <p className="text-[28px]">{title}</p>
     <button className="border-2 border-themecolor rounded-lg bg-themecolor/20 w-[140px] p-1 relative overflow-hidden">
      <div className="group-hover:p-3 transition-all duration-300">
       <span className="truncate absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
        Read more
       </span>
      </div>
     </button>
    </div>
   </div>
  </Link>
 );
}

import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Import your images
import background1 from "../../mediaAssets/bg5.jpg";
import background2 from "../../mediaAssets/bg2.jpg";
import background3 from "../../mediaAssets/bg3.jpg";
import background4 from "../../mediaAssets/bg4.jpg";
import background5 from "../../mediaAssets/bg5.jpg";
import background6 from "../../mediaAssets/backgroundfesk.svg";
import background7 from "../../mediaAssets/bg7.jpg";

const images = [background1, background2, background3, background4, background5, background6, background7];

export default function Header() {
 const settings = {
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 10000,
  fade: true,
  cssEase: "linear",
 };

 return (
  <div className="relative overflow-hidden text-center" style={{ height: "600px" }}>
   <Slider {...settings}>
    {images.map((image, index) => (
     <div key={index} className="relative h-full w-full">
      <div
       className={`relative bg-cover bg-center bg-no-repeat h-full w-full ${index % 2 === 0 ? "zoom-in" : "zoom-out"}`}
       style={{
        backgroundImage: `url(${image})`,
        height: "100vh",
        filter: "brightness(0.4)",
        backgroundPosition: "center",
        backgroundSize: "cover",
       }}
      />
     </div>
    ))}
   </Slider>

   {/* Overlay for the text content */}
   <div className="absolute inset-0 flex items-center justify-center text-white">
    <div className="text-center px-4">
     <h2 className="text-[40px] md:text-[60px] font-semibold drop-shadow-lg" data-aos="fade-down">
      FEDERATA ESPORTS KOSOVA
     </h2>
     <h4 className="my-6 md:my-6 text-[20px] md:text-[30px] font-light drop-shadow-md" data-aos="fade-up">
      Mundësia juaj për të shndërruar pasionin për lojërat elektronike në sukses.
     </h4>
     <Link to="/about-us">
      <button
       type="button"
       className="rounded border-2 border-themecolor px-7 pb-[8px] pt-[10px] text-sm font-medium uppercase leading-normal text-neutral-50 hover:text-themecolor transition duration-150 ease-in-out hover:border-neutral-100 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-neutral-100 focus:border-neutral-100 focus:text-neutral-100 focus:outline-none focus:ring-0 active:border-neutral-200 active:text-neutral-200 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10 backdrop-blur-sm bg-themecolor/30"
       data-twe-ripple-init
       data-twe-ripple-color="light"
       data-aos="fade-up"
      >
       About us
      </button>
     </Link>
    </div>
   </div>
  </div>
 );
}

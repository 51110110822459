import React, { useEffect, useState } from "react";
import background from "../mediaAssets/ourStory.svg";
import backgroundSecond from "../mediaAssets/aboutus4.svg";

export default function About() {
 const [content, setContent] = useState([]);
 const [members, setMembers] = useState([]);

 useEffect(() => {
  // Fetch the content data
  fetch("https://api.fesk.gg/api/v1/about/content")
   .then((response) => response.json())
   .then((data) => setContent(data))
   .catch((error) => console.error("Error fetching content:", error));

  // Fetch the members data
  fetch("https://api.fesk.gg/api/v1/about/members")
   .then((response) => response.json())
   .then((data) => setMembers(data))
   .catch((error) => console.error("Error fetching members:", error));
 }, []);

 return (
  <div>
   {/* Section 1 - Our Story */}
   {content.map(
    (section) =>
     section.section === "ourStory" && (
      <>
       <div
        key={section._id}
        className="relative overflow-hidden bg-cover bg-no-repeat p-12 text-center"
        style={{
         backgroundImage: `url(${background})`,
         backgroundPosition: "center",
         backgroundSize: "cover",
         height: "500px",
        }}
       >
        <div
         className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed"
         style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
         <div className="flex flex-col h-full items-center justify-center">
          <img alt="logo" src="/assets/images/fesklogo.svg" className="w-[300px] my-4" />
          <div className="text-white">
           <h2 className="text-[40px] font-semibold">{section.title}</h2>
           <h4 className="mb-6 text-[24px] font-light">{section.subtitle}</h4>
          </div>
         </div>
        </div>
       </div>
       <div className="container md:py-24 py-12">
        <div className="flex md:flex-row  justify-between md:mx-24 md:gap-20 gap-10">
         <div className="flex flex-col">
          <img alt="about" src="/assets/images/aboutus/aboutus1.svg" />
          <h1 className="md:text-[30px] text-[20px] md:py-10 py-4" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
           {section.titleContent2}
          </h1>
          <p className="md:py-4 py-2 md:text-[20px] text-[10px]">{section.descriptionContent2} </p>
         </div>
         <div className="flex flex-col md:gap-6 gap-2">
          <h1 className="md:text-[30px] text-[20px]">{section.titleContent1}</h1>
          <p className="py-4 md:text-[20px] text-[10px]">{section.descriptionContent1}</p>
          <img alt="about" src="/assets/images/aboutus/aboutus2.svg" />
          <img alt="about" src="/assets/images/aboutus/aboutus3.svg" className="pt-4" />
         </div>
        </div>
       </div>
      </>
     )
   )}

   {/* Section 4 - Who is FESK */}
   <div className="container text-center py-12">
    {content.map(
     (section) =>
      section.section === "aboutUs" && (
       <>
        <h1 className="text-[40px] font-semibold mb-4">{section.title}</h1>
        <p className="text-[18px]">{section.subtitle}</p>
       </>
      )
    )}
   </div>

   {/* Section 5 - Another Background */}
   <div
    className="relative overflow-hidden bg-cover bg-no-repeat p-12 text-center"
    style={{
     backgroundImage: `url(${backgroundSecond})`,
     backgroundPosition: "center",
     backgroundSize: "cover",
     height: "500px",
    }}
   >
    <div
     className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed"
     style={{ backgroundColor: "rgba(0, 0, 0.2, 0.2)" }}
    >
     <div className="flex flex-col h-full items-center">
      <img alt="logo" src="/assets/images/fesklogo.svg" className="w-[300px] mt-12 " />
     </div>
    </div>
   </div>

   {/* Section 3 - Members */}
   {/* <div className="container text-center py-12">
      <h1 className="text-[40px] font-semibold mb-4">FESK Members</h1>
      <div className="grid md:grid-cols-2 lg:grid-cols-4 grid-cols-1 gap-4 py-12">
      {members.map((member) => (
        <div key={member._id} className="border shadow-xl rounded-lg cursor-pointer group">
        <img
          alt="member"
          src={member.imageUrl || "/assets/images/members/member.svg"}
          className="w-full opacity-80 group-hover:opacity-100 transition-opacity rounded-lg"
        />
        <div className="p-2 flex flex-col gap-2">
          <h1 className="font-bold text-[18px]">{member.name}</h1>
          <h2 className="font-semibold text-[14px]">{member.role}</h2>
          <p>{member.description}</p>
        </div>
        </div>
      ))}
      </div>
    </div> */}
  </div>
 );
}
